import useStore from './root';

export const useSnackbar = () => ({
  openSnackbar: useStore((store) => store.openSnackbar),
  closeSnackbar: useStore((store) => store.closeSnackbar),
  showSnackbar: useStore((store) => store.showSnackbar),
  message: useStore((store) => store.message),
  severity: useStore((store) => store.severity),
});

export const useClaimStatus = () => ({
  claimID: useStore((store) => store.claimID),
  message: useStore((store) => store.message),
  processStatus: useStore((store) => store.processStatus),
  claimType: useStore((store) => store.claimType),
  claimantIdentity: useStore((store) => store.claimantIdentity),
  subscribeToClaimStatus: useStore((store) => store.subscribeToClaimStatus),
  clearClaimStatus: useStore((store) => store.clearClaimStatus),
  setClaimID: useStore((store) => store.setClaimID),
  fetchClaimStatus: useStore((store) => store.fetchClaimStatus),
  hasFetchedClaimStatus: useStore(
    (store) =>
      store.claimFetchStatus !== 'idle' && store.claimFetchStatus !== 'pending'
  ),
  isLoadingSubmissionData: useStore(
    (store) => store.claimFetchStatus === 'pending'
  ),
  hasPreviousSubmission: useStore((store) => store.claimID !== null),
});

export const usePaymentMethod = () => ({
  paymentMethod: useStore((store) => store.paymentMethod),
  fetchPaymentMethod: useStore((store) => store.fetchPaymentMethod),
  setPaymentMethod: useStore((store) => store.setPaymentMethod),
  clearPaymentMethod: useStore((store) => store.clearPaymentMethod),
  hasFetchedPaymentMethod: useStore(
    (store) =>
      store.paymentFetchStatus !== 'idle' &&
      store.paymentFetchStatus !== 'pending'
  ),
});
