import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { firestore } from '../hooks/useFirebase';

// Definitions
const createSnackbar = (set) => ({
  showSnackbar: false,
  message: '',
  severity: 'info',
  closeSnackbar: () =>
    set((state) => {
      state.showSnackbar = false;
    }),
  openSnackbar: (message, severity) =>
    set((state) => {
      state.showSnackbar = true;
      state.message = message ?? state.message;
      state.severity = severity ?? state.severity;
    }),
});

const createClaimStatusStore = (set) => ({
  claimID: null,
  message: null,
  processStatus: null,
  claimType: null,
  claimantIdentity: null,
  claimFetchStatus: 'idle', // idle | pending | success | error
  subscribeToClaimStatus: (caseID, userID) => {
    const docRef = doc(firestore, `ClaimStatus/${caseID}/Claims/${userID}`);
    return onSnapshot(docRef, (doc) => {
      const {
        message,
        claimId,
        processStatus,
        claimType,
        claimantIdentity,
      } = doc.data();

      set((state) => {
        state.claimID = claimId;
        state.message = message;
        state.processStatus = processStatus;
        state.claimType = claimType;
        state.claimantIdentity = claimantIdentity;
      });
    });
  },
  fetchClaimStatus: async (caseID, userID) => {
    set((state) => {
      state.claimFetchStatus = 'pending';
    });
    const docRef = doc(firestore, `ClaimStatus/${caseID}/Claims/${userID}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const {
        message,
        claimId,
        processStatus,
        claimType,
        claimantIdentity,
      } = docSnap.data();

      set((state) => {
        state.claimID = claimId;
        state.message = message;
        state.processStatus = processStatus;
        state.claimType = claimType;
        state.claimantIdentity = claimantIdentity;
      });
      set((state) => {
        state.claimFetchStatus = 'success';
      });

      return {
        message,
        claimId,
        processStatus,
        claimantIdentity,
      };
    }
    set((state) => {
      state.claimFetchStatus = 'error';
    });
  },
  clearClaimStatus: () => {
    set((state) => {
      state.claimID = null;
      state.message = null;
      state.processStatus = null;
      state.claimType = null;
      state.claimFetchStatus = 'idle';
      state.claimantIdentity = null;
    });
  },
  setClaimID: (claimID) => {
    set((state) => {
      state.claimID = claimID;
    });
  },
});

const createPaymentStore = (set) => ({
  paymentMethod: null,
  fetchPaymentMethod: async (caseID, claimID) => {
    set((state) => {
      state.paymentFetchStatus = 'pending';
    });
    const docRef = doc(firestore, `Payments/${caseID}-${claimID}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const { paymentMethod } = docSnap.data();
      return set((state) => {
        state.paymentMethod = paymentMethod;
        state.paymentFetchStatus = 'success';
      });
    }
    set((state) => {
      state.paymentFetchStatus = 'error';
    });
  },
  paymentFetchStatus: 'idle', // idle | pending | success | error
  setPaymentMethod: (paymentMethod) =>
    set((state) => {
      state.paymentMethod = paymentMethod;
    }),
  clearPaymentMethod: () =>
    set((state) => {
      state.paymentMethod = null;
      state.claimFetchStatus = 'idle';
    }),
});

const root = (set, get) => ({
  ...createSnackbar(set, get),
  ...createClaimStatusStore(set, get),
  ...createPaymentStore(set, get),
});

export default create(immer(root));
